<script setup lang="ts">
import type { SlideEventData } from '@mop/types';
import { getElementUiObject, getCard } from '@mop/cms/utils/utils';
import type { CmsUiCardCarousel } from './uiCmsCard';

defineOptions({
  name: 'UiCmsCardCarousel',
});

const emit = defineEmits(['slide-change']);

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  lazyLoad: {
    type: Boolean,
    default: false,
  },
});

const rootElement = ref<HTMLElement | null>(null);

const element: CmsUiCardCarousel = getElementUiObject(props.data, {
  cards: props.data.cards?.map((cartData: any) => {
    const card = getCard(cartData);
    if (card) {
      card.trackingId = card.media?.trackingId;
      card.headline.level ??= 3;
    }
    return {
      ...card,
      imageType: 'cms-card-carousel',
    };
  }),
});

function handleSlideChange(slideData: SlideEventData) {
  emit('slide-change', rootElement.value, slideData);
}
</script>

<template>
  <div v-if="element.cards.length >= 3" ref="rootElement" v-storyblok-editable="element" class="ui-cms-card-carousel">
    <Ui2Carousel
      show-arrows
      show-peek
      no-gap
      :max-slides-count="3"
      :class="['ui-cms-card-carousel__slider', `ui-cms-card-carousel__slider--${element.cards.length}`]"
      @slide-change="handleSlideChange"
    >
      <UiCmsCardItem
        v-for="(card, index) in element.cards"
        :key="index"
        :data="card"
        :lazy-load="lazyLoad"
        :data-track-element-item="`${card.trackingId}|${index + 1}`"
      />
    </Ui2Carousel>
  </div>
</template>

<style lang="scss" scoped>
.ui-cms-card-carousel {
  display: flex;
  flex-wrap: wrap;
  background-color: $white;
}

.ui-cms-card-carousel__slider {
  --ui-carousel-item-count: 3;

  @include apply-upto(medium) {
    --ui-carousel-item-count: 2;
  }

  @include apply-upto(small) {
    --ui-carousel-item-count: 1;
    --ui-carousel-item-peek-width: 40px;
  }
}

.ui-cms-card-carousel__slider--3 {
  @include apply-from(medium) {
    --ui-carousel-item-peek-width: 0px;
    --ui-carousel-item-count: 3;
  }
}
</style>

<script setup lang="ts">
import type { SlideEventData } from '@mop/types';
import { getElementUiObject, getHeadlineWithAllOptions, getLink, getMedia, getCta } from '@mop/cms/utils/utils';
import type { CmsUiSliderItem, CmsUiSlider } from './uiCmsSlider';

defineOptions({
  name: 'UiCmsSlider',
});

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  lazyLoad: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['slide-change']);

const data: any = props.data;

function getCmsUiSliderItem(object: any, keepAspectRatio: boolean): CmsUiSliderItem {
  const slide: CmsUiSliderItem = getElementUiObject(object, {
    headline: getHeadlineWithAllOptions(object),
    headlineSize: object.headlineSize,
    subline: object.subline,
    text: object.text,
    arrowColor: object.arrowColor?.color,
    arrowColorLeft: object.arrowColorLeft?.color,
    arrowColorRight: object.arrowColorRight?.color,
    cta: getCta(object.cta?.[0]),
    link: getLink(object.link),
    textColor: object.textColor?.color,
    mobileTextColor: object.mobileTextColor?.color || object.textColor?.color,
    textAlignment: object.textAlignment || 'left',
    media: getMedia(object.media?.[0], keepAspectRatio),
  });
  slide.trackingId = slide.media?.trackingId;
  return slide;
}

const autoPlaySpeed: number = (data.autoPlaySpeed || 3) * 1000;
const keepAspectRatio: boolean = data.keepAspectRatio === true;
const slides: CmsUiSliderItem[] = [];
data.slides.forEach((slideObject: any) => {
  if (slideObject.slides) {
    // Legacy split slider is not supported anymore
    return;
  }
  if (slideObject.isVisible === false) {
    return;
  }
  slides.push(getCmsUiSliderItem(slideObject, keepAspectRatio));
});

const element: CmsUiSlider = getElementUiObject(data, {
  slides,
  hideArrows: data.hideArrows,
  autoPlay: data.autoPlay === true ? autoPlaySpeed : data.autoPlay ?? false,
  keepAspectRatio,
});

const bulletList: any = slides.map(() => {
  return {
    type: 'circle',
  };
});

const rootElement = ref();
function handleSlideChange(slideData: SlideEventData) {
  const slide = element.slides[slideData.activeIndex];
  const arrowColors = {
    color: slide?.arrowColor || '',
    colorLeft: slide?.arrowColorLeft || '',
    colorRight: slide?.arrowColorRight || '',
  };

  rootElement.value.$el.setAttribute(
    'style',
    `color: ${arrowColors.color};--arrow-left-color: ${arrowColors.colorLeft};--arrow-right-color: ${arrowColors.colorRight};`,
  );

  emit('slide-change', rootElement.value.$el, slideData);
}
</script>

<template>
  <Ui2Carousel
    v-if="element.slides.length > 0"
    :key="`slider-${element.slides.length}`"
    ref="rootElement"
    v-storyblok-editable="element"
    :show-arrows="!element.hideArrows"
    :keep-aspect-ratio="element.keepAspectRatio"
    show-bullets
    :bullet-list="bulletList"
    slide-full-page
    no-gap
    :auto-play="element.autoPlay"
    slide-classname="ui-cms-slider__slide"
    class="ui-cms-slider"
    @slide-change="handleSlideChange"
  >
    <UiCmsSliderSlide
      v-for="(sliderElement, index) in element.slides"
      :key="`slide-${index}`"
      :data="sliderElement"
      :lazy-load="lazyLoad || index > 0"
      :data-track-element-item="`${sliderElement.trackingId}|${index + 1}`"
    />
  </Ui2Carousel>
</template>

<style lang="scss" scoped>
.ui-cms-slider {
  :deep(.ui-cms-slider__slide) {
    position: relative;
    overflow: hidden;

    &:first-child {
      width: 100%;
    }
  }
}
</style>

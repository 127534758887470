<script setup lang="ts">
import type { UiCarouselArrowDirection, UiCarouselArrowSize } from '@mop/ui2/types';

defineOptions({
  name: 'Ui2CarouselArrow',
});

defineProps({
  size: {
    type: String as PropType<UiCarouselArrowSize>,
    default: 'sm',
  },
  direction: {
    type: String as PropType<UiCarouselArrowDirection>,
    default: 'next',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  hidden: {
    type: Boolean,
    default: false,
  },
});

const sizeMap: Record<UiCarouselArrowSize, number> = {
  sm: 16,
  md: 20,
  lg: 24,
};
</script>

<template>
  <button
    :class="[
      'ui-carousel-arrow',
      `ui-carousel-arrow--${size}`,
      {
        'ui-carousel-arrow--disabled': disabled,
        'ui-carousel-arrow--hidden': hidden,
      },
    ]"
    :aria-label="direction === 'prev' ? $ui2Config.ui2T('common.global.back') : $ui2Config.ui2T('common.global.next')"
  >
    <Ui2Icon v-if="direction === 'prev'" name="arrow-left" :width="sizeMap[size]" :height="sizeMap[size]" />
    <Ui2Icon v-else name="arrow-right" :width="sizeMap[size]" :height="sizeMap[size]" />
  </button>
</template>

<style lang="scss">
.ui-carousel-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: $space-24;
  height: $space-24;
  padding: $space-8;
  gap: $space-8;
  flex-shrink: 0;
  color: $color-text-body-primary;
  cursor: pointer;
  border-radius: $border-radius-rounded-full;
  background: rgba(255, 255, 255, 0.9);
  border: none;

  &::after {
    @include v2-z(global, content);
    position: absolute;
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
    border: 2px solid $color-border-focus;
    border-radius: $border-radius-rounded-full;
    transition: all $animation-duration-medium $animation-type-standard;
    opacity: 0;
    content: '';
  }

  &:hover {
    background: $color-surface-primary;
    box-shadow: $shadow-md;
  }

  &:active {
    background: rgba(255, 255, 255, 0.9);
    box-shadow: none;
  }

  &:focus-visible {
    &::after {
      opacity: 1;
    }
  }
}

.ui-carousel-arrow--disabled {
  cursor: not-allowed;
  color: $color-text-body-disabled;
}

.ui-carousel-arrow--md {
  width: $space-32;
  height: $space-32;
}

.ui-carousel-arrow--lg {
  width: $space-40;
  height: $space-40;
  padding: 10px;
}

.ui-carousel-arrow--hidden {
  display: none;
}
</style>

import type { Timer } from '@mop/types';

type ScriptAttribute = {
  key: string;
  value: string;
};

export type ScriptProperties = {
  source: string;
  id?: string;
  triggerCallbackIfExists?: boolean;
  async?: boolean;
  defer?: boolean;
  callback?: () => void;
  attributes?: ScriptAttribute[];
};

export type CssProperties = {
  source: string;
  id?: string;
};

export function generateRandomChars(length = 32): string {
  return [...Array(length)].reduce((hash) => hash + Math.random().toString(16).slice(-1), '');
}

export const isClient: boolean = typeof window !== 'undefined';

export const isCssVariablesSupported: boolean = isClient
  ? window.CSS && CSS.supports('color', 'var(--fake-var)')
  : true;

export function asyncTimeout(milliSeconds: number) {
  return new Promise((resolve) => setTimeout(resolve, milliSeconds));
}

export function isReversedAddress(countryCode: string): boolean {
  return ['fr', 'ie', 'lu', 'gb', 'us'].includes(countryCode);
}

export function isPhoneMandatory(countryCode: string): boolean {
  return ['pl', 'lu'].includes(countryCode);
}

export function isHouseNumberRequired(countryCode: string): boolean {
  return !['sv', 'ch', 'gb'].includes(countryCode);
}

export function getHashFromString(value: string) {
  let hash = 0;
  if (value.length === 0) {
    return hash;
  }
  for (let i = 0; i < value.length; i++) {
    const char: number = value.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}

export function stripHtmlTagsFromString(html: string): string {
  return html.replace(/(<([^>]+)>)/gi, '');
}

export function loadScript(properties: ScriptProperties) {
  if (!isClient) {
    return;
  }
  if (document.getElementById(properties.source)) {
    return properties.triggerCallbackIfExists && properties.callback && properties.callback();
  }

  const script: HTMLScriptElement = document.createElement('script');
  script.async = properties.async ?? true;
  script.defer = properties.defer ?? true;
  script.src = properties.source;
  script.id = properties.id ?? properties.source;
  properties.attributes?.forEach((attribute) => {
    script.setAttribute(attribute.key, attribute.value);
  });

  if (properties.callback) {
    script.onload = () => properties.callback && properties.callback();
  }

  document.head.appendChild(script);
}

export function loadCss(properties: CssProperties) {
  if (!isClient) {
    return;
  }
  if (document.getElementById(properties.source)) {
    return;
  }

  const css: HTMLLinkElement = document.createElement('link');
  css.rel = 'stylesheet';
  css.href = properties.source;
  css.id = properties.id ?? properties.source;

  document.head.appendChild(css);
}

export function isIE(): boolean {
  if (!isClient) {
    return false;
  }
  // documentMode is only available in IE Browsers (Edge is not included)
  return Boolean('documentMode' in document);
}

export function isSafari(): boolean {
  if (!isClient) {
    return false;
  }
  return navigator.userAgent.includes('Safari') && !navigator.userAgent.includes('Chrome');
}

export function isFirefox() {
  return /firefox/i.test(navigator.userAgent);
}

export function isIPhone(): boolean {
  return isClient && navigator.platform === 'iPhone';
}

export function isAndroid(): boolean {
  return isClient && /(android)/i.test(navigator.userAgent);
}

export function scrollToElement(errorElement: HTMLElement, scrollViewOptions?: ScrollIntoViewOptions) {
  if (!errorElement) {
    return;
  }
  errorElement.scrollIntoView(
    scrollViewOptions || {
      behavior: 'smooth',
      block: 'center',
    },
  );
}

export function scrollToErrorElement(
  errorElement: HTMLElement,
  closestParentSelector = '.form-group__row',
  scrollViewOptions?: ScrollIntoViewOptions,
) {
  if (!errorElement) {
    return;
  }
  const formRowElement: HTMLElement = errorElement.closest(closestParentSelector) ?? errorElement;
  formRowElement.scrollIntoView(
    scrollViewOptions || {
      behavior: 'smooth',
      block: 'center',
    },
  );
}

export function scrollToPosition(top = 0, enableSmoothScroll = true) {
  if (!isClient) {
    return;
  }
  try {
    const options: ScrollToOptions = {
      top,
    };
    if (enableSmoothScroll) {
      options.behavior = 'smooth';
    }
    window.scrollTo(options);
  } catch (error) {
    window.scrollTo(0, top);
  }
}

export async function scrollToAnchor(href: string, delay = 100) {
  if (!isClient || !window.scrollTo || !href || !href.includes('#')) {
    return;
  }
  const id = href.substring(href.indexOf('#') + 1);

  await asyncTimeout(delay);
  let targetElement = document.getElementById(id);
  if (!targetElement) {
    await asyncTimeout(200);
    targetElement = document.getElementById(id);
    if (!targetElement) {
      await asyncTimeout(300);
      targetElement = document.getElementById(id);
      if (!targetElement) {
        return;
      }
    }
  }

  if (!targetElement) {
    scrollToPosition(0);
    return;
  }

  // Reset elements is to prevent browser from jumping
  targetElement.id = '';
  const offsetTop: number = targetElement.getBoundingClientRect().top + window.scrollY;
  scrollToPosition(offsetTop);
  targetElement.id = id;
}

export async function asyncForEach(array: any[], callback: (params: any) => Promise<void>) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index]);
  }
}

export function sortByArray(list: string[], order: string[]): string[] {
  return order
    .filter((orderItem) => list.includes(orderItem))
    .concat(list.filter((listItem) => !order.includes(listItem)));
}

/**
 * Due to browser inconsistency to use passive events during scroll, touchmove etc
 * we need to validate it's support, otherwise browser will throw an error
 */
let hasPassiveEventsCache: boolean | null = null;
export function hasPassiveEvents(fnContext?: EventListenerOrEventListenerObject): boolean {
  if (hasPassiveEventsCache !== null) {
    return hasPassiveEventsCache;
  }
  hasPassiveEventsCache = false;
  const passiveTestOptions: any = {
    get passive() {
      hasPassiveEventsCache = true;
      return undefined;
    },
  };
  // @ts-ignore: Unknown event type (not key of WindowEventMap).
  window.addEventListener('testPassive', fnContext, passiveTestOptions);
  // @ts-ignore: Unknown event type (not key of WindowEventMap).
  window.removeEventListener('testPassive', fnContext, passiveTestOptions);

  return hasPassiveEventsCache;
}

type PassiveEventOption =
  | {
      passive: boolean;
    }
  | undefined;

/**
 * Due to browser inconsistency to use passive events during scroll, touchmove etc
 * we need to validate it's support, otherwise browser will throw an error
 */
export function getPassiveEventOption(passive = true): PassiveEventOption {
  if (hasPassiveEvents()) {
    return {
      passive,
    };
  }
}

export function throttle(callback: (...args: any[]) => void, wait = 200) {
  let timer: Timer;
  let currentTime: number;

  return function throttledCallback(...args: unknown[]) {
    if (!currentTime) {
      // eslint-disable-next-line no-useless-call
      callback.call(null, ...args);
      currentTime = Date.now();
      return;
    }

    clearTimeout(timer);
    timer = setTimeout(
      function debouncedCall() {
        if (Date.now() - currentTime >= wait) {
          // eslint-disable-next-line no-useless-call
          callback.call(null, ...args);
          currentTime = Date.now();
        }
      },
      wait - (Date.now() - currentTime),
    );
  };
}

export function throttleWithoutDelay<T extends (...args: any[]) => void>(callback: T) {
  let frameId: number | null = null;
  let lastArgs: Parameters<T> | null = null;

  const tick = () => {
    frameId = null; // Reset so a new frame can be scheduled.
    if (lastArgs !== null) {
      // eslint-disable-next-line n/no-callback-literal
      callback(...lastArgs);
      lastArgs = null;
    }
  };

  const throttled = (...args: Parameters<T>) => {
    lastArgs = args;
    if (frameId === null) {
      frameId = requestAnimationFrame(tick);
    }
  };

  throttled.cancel = () => {
    if (frameId !== null) {
      cancelAnimationFrame(frameId);
      frameId = null;
      lastArgs = null;
    }
  };

  return throttled;
}

export function removeValuesFromArray(
  array: Array<number | string | boolean>,
  ...values: Array<number | string | boolean>
) {
  if (!Array.isArray(array) || array.length === 0) {
    return;
  }
  values.forEach((value) => {
    const index: number = array.indexOf(value);
    if (index !== -1) {
      array.splice(index, 1);
    }
  });
}

export function addValuesToArray(array: Array<number | string | boolean>, ...values: Array<number | string | boolean>) {
  if (!Array.isArray(array)) {
    return;
  }
  values.forEach((value) => {
    const index: number = array.indexOf(value);
    if (index === -1) {
      array.push(value);
    }
  });
}

export function isNumber(value: string) {
  return /^\d+$/.test(value);
}

export function createCanonicalTag(baseUrl: string, url: string) {
  if (!url) {
    return;
  }
  if (!url.startsWith('http')) {
    url = new URL(url, baseUrl).href;
  }

  return {
    rel: 'canonical',
    href: decodeURIComponent(url),
  };
}

export function parseAxiosErrorResponseUrl(response: any): string | undefined {
  let url: string | undefined = response.config?.baseURL
    ? `${response.config.baseURL}${response.config.url}`
    : response.config?.url;
  if (response.config?.params) {
    url += Object.keys(response.config.params).reduce((path: string, key: string) => {
      const value: string = response.config?.params[key];
      path += `&${key}=${value}`;
      return path;
    }, '?');
  }
  return url;
}

export function clamp(num: number, min: number, max: number) {
  return num <= min ? min : num >= max ? max : num;
}

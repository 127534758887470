import { logWarning } from '@mop/shared/utils/logger';
import { localStorageGet, localStorageSet } from '@mop/shared/utils/localStorage';
import type {
  ClientResponse,
  ProductProjection,
  ProductPagedSearchResponse,
  LocalizedString,
  ProductProjectionPagedSearchResponse,
} from '@commercetools/platform-sdk';
import { variantListModel } from '@/models';
import type { Alternate } from '@/types/cms';
import type { CountryConfigModel } from '@/types/countryConfig';
import { localeList } from '@/i18n/localeList';
import { getMopPriceFromCommercetools } from '@/models/utils/priceUtils';
import { unwrapLocalizedStringVariantAttributes } from '@/models/utils/productUtils';
import type {
  ProductBadge,
  ProductModel,
  ProductImage,
  VariantModel,
  VariantAvailability,
  Gender,
  ProductDataPopularityFlag,
} from '@/types/product';

const BRAND_IDS = {
  casual: 'casual',
  denim: 'denim',
  shoes: 'shoes',
  accessoires: 'accessoires',
  corsina: 'corsina',
  home: 'home',
  junior: 'junior',
} as const;

type BrandId = keyof typeof BRAND_IDS;

function getValueFromLocalizedString(localizedString?: LocalizedString) {
  return Object.values(localizedString ?? {})[0] ?? '';
}

const SUSTAINABILITY_FLAG_TRANSLATION_KEY = 'product.flag.sustainable';
const SOON_AVAILABLE_TRANSLATION_KEY = 'product.flag.soon_available';
const SOLD_OUT_TRANSLATION_KEY = 'product.stock.soldout';

function getImageIndexFromUrl(url: string): { index: number; division: number } {
  const matches = url.match(/_(\d+)\.(\d+)\.([a-z+]{3,4})/);
  return matches
    ? {
        division: parseInt(matches[1]),
        index: parseInt(matches[2]),
      }
    : { index: 0, division: 0 };
}

export function productModel(response: ClientResponse<ProductProjection> | null) {
  const localCache: {
    breadcrumbs?: any;
    imageUrlsPDP?: ProductImage[];
    sizes?: any;
    soldOut?: boolean;
    soonAvailable?: boolean;
    isInTransit?: boolean;
    variants?: VariantModel[];
    flag?: string;
    availabilityFlag?: string;
    alternates?: Alternate[];
  } = {
    sizes: [],
    breadcrumbs: {},
  };

  const masterVariant = response?.body?.masterVariant;
  const masterAttributes = unwrapLocalizedStringVariantAttributes(masterVariant);

  return {
    isInitialized(): boolean {
      return response !== null && response?.statusCode === 200;
    },

    hasError(): boolean {
      return response?.statusCode !== undefined && response.statusCode !== 200;
    },

    hasErrorNotFound(): boolean {
      return response?.statusCode === constants.ERROR_CODE.NOT_FOUND;
    },

    isPromoQualified(): boolean {
      return masterAttributes.promoQualifier ?? false;
    },

    getId() {
      return response?.body?.id ?? '';
    },

    getMopId(): string {
      return response?.body?.key ?? '';
    },

    getMopMasterId(): string {
      return masterAttributes.masterKey ?? '';
    },

    getVimeoId(): string {
      return masterAttributes.vimeoId ?? '';
    },

    // Datasource: CMS -> global
    getPopularityFlag($mopConfig: CountryConfigModel): ProductDataPopularityFlag | undefined {
      if (this.isSoonAvailable() || this.isInTransit()) {
        return;
      }
      const showOnSaleProducts = $mopConfig.isPopularityFlagEnabledForSaleProducts();
      if (!showOnSaleProducts && this.hasDiscount()) {
        return;
      }

      const popularityFlag =
        $mopConfig.getPopularityFlagPreference(this.getMopId(), 'itemsOnTrend') ||
        $mopConfig.getPopularityFlagPreference(this.getMopId(), 'itemsBestseller') ||
        $mopConfig.getPopularityFlagPreference(this.getMopId(), 'itemsPopular');
      if (popularityFlag) {
        return popularityFlag;
      }
    },

    getName(): string {
      return getValueFromLocalizedString(response?.body?.name);
    },

    getShortDescription(): string {
      return masterAttributes.shortDescription ?? '';
    },

    getCompositeName(): string {
      return [this.getName(), this.getShortDescription()].join(' ');
    },

    isMen() {
      return this.getGender() === 'M';
    },

    isWomen() {
      return this.getGender() === 'W';
    },

    getGender() {
      return (masterAttributes.gender?.[0] ?? '') as Gender;
    },

    getBrandFromId(): string {
      const brandId = this.getBrandId();
      const brandMap: any = {
        denim: 'DENIM',
        junior: 'JUNIOR',
        home: 'HOME',
      };
      return brandMap[brandId] ?? '';
    },

    getSimpleBrandName(): string {
      const subBrand: string | null = this.getBrandFromId();
      return subBrand || constants.BRAND_NAME_DEFAULT;
    },

    getBrandName(): string {
      const brand: string = constants.BRAND_NAME_DEFAULT;
      const subBrand: string | null = this.getBrandFromId();
      return subBrand === '' ? brand : `${brand} ${subBrand}`;
    },

    getBrandId(): BrandId {
      let brandId = masterVariant?.attributes?.find((attribute) => attribute.name === 'brand')?.value?.[0]?.key || '';
      const legacyBrandMap: any = {
        200: 'denim',
        500: 'junior',
        600: 'home',
      };
      brandId = legacyBrandMap[brandId] ?? brandId.toLowerCase();
      const foundBrandId = Object.keys(BRAND_IDS).find((key) => brandId.includes(key));
      return foundBrandId || brandId;
    },

    getCareInstructions(): string[] {
      return masterAttributes.careInstructions?.split(',') ?? [];
    },

    getMopCategoryIds(): string[] {
      // Don't use assigned CT categories (response?.body?.categories), they might differ
      return masterAttributes.mopCategories ?? [];
    },

    getPrimaryCategoryMopId(): string {
      return masterAttributes.primaryCategory ?? '';
    },

    getCategoryIds() {
      return response?.body?.categories?.map((category) => category.id) ?? [];
    },

    getLifecycleFlag(): string {
      return masterAttributes.lifecycleFlag ?? '';
    },

    isNew(): boolean {
      // Use assigned CT categories
      return (response?.body?.categories?.map((category) => category.obj?.key ?? '') ?? []).some((id) =>
        id.includes('new'),
      );
    },

    getCustomFlag(): string {
      return masterAttributes.customProductLabel || this.getSustainabilityFlag() || '';
    },

    getSustainabilityFlag(): string {
      // More granular sustaiability flags will be implemented here
      return this.getSustainabilityId() ? SUSTAINABILITY_FLAG_TRANSLATION_KEY : '';
    },

    // badge = lifecycle flag
    getBadge(): ProductBadge | null {
      if (this.getLifecycleFlag()) {
        return {
          sale: false,
          text: `product.badge.${this.getLifecycleFlag().toLowerCase()}`,
        };
      }
      if (this.isNew()) {
        return {
          sale: false,
          text: 'product.badge.new',
        };
      }
      if (this.hasDiscount()) {
        return {
          sale: true,
          text: 'product.badge.sale',
        };
      }
      return null;
    },

    hasDiscount() {
      return (this.getPrice()?.salePercentage || 0) > 0;
    },

    getPrice() {
      if (masterVariant?.price) {
        return getMopPriceFromCommercetools(masterVariant?.price);
      }
      const variantWithPrice = this.getVariants().find((variant) => variant.hasPrice());
      return variantWithPrice?.getPrice() || getMopPriceFromCommercetools();
    },

    getSeason(): string {
      return masterAttributes.season ?? '';
    },

    isSoldOut(): boolean {
      if (localCache.soldOut !== undefined) {
        return localCache.soldOut;
      }
      const variants: VariantModel[] = this.getVariants();
      localCache.soldOut = variants.length === 0 || variants.every((variant) => !variant.getAvailability().isInStock);
      return localCache.soldOut;
    },

    isSoonAvailable(): boolean {
      if (localCache.soonAvailable !== undefined) {
        return localCache.soonAvailable;
      }
      const variants: VariantModel[] = this.getVariants();
      localCache.soonAvailable =
        variants.length > 0 && variants.every((variant) => variant.getAvailability().isComingSoon);
      return localCache.soonAvailable;
    },

    isInTransit(): boolean {
      if (localCache.isInTransit !== undefined) {
        return localCache.isInTransit;
      }
      const variants: VariantModel[] = this.getVariants();
      localCache.isInTransit =
        variants.length > 0 && variants.every((variant) => variant.getAvailability().isInTransit);
      return localCache.isInTransit;
    },

    getImageList(): ProductImage[] {
      return masterVariant?.images?.slice(0, -1) ?? [];
    },

    getNamesOfImageList(): string {
      return this.getImageList()
        .map((image) => {
          const url = image.url;
          return url.substring(url.lastIndexOf('/') + 1);
        })
        .join(',');
    },

    getImageByIndex(index = PRODUCT_IMAGE_INDEX.STANDARD, division?: number): string {
      return (
        masterVariant?.images?.find((image) => {
          const { index: imageIndex, division: imageDivision } = getImageIndexFromUrl(image.url);
          return (imageDivision === division || !division) && imageIndex === index;
        })?.url ??
        masterVariant?.images?.[0]?.url ??
        ''
      );
    },

    getStandardImage(): string {
      return this.getImageByIndex(PRODUCT_IMAGE_INDEX.STANDARD);
    },

    getImageIndexForRecos(isHover = false): number {
      const isClothing = this.getBrandId() === 'casual' || this.getBrandId() === 'denim';
      if (isClothing) {
        return isHover ? PRODUCT_IMAGE_INDEX.STANDARD : PRODUCT_IMAGE_INDEX.BUST;
      }
      return isHover ? PRODUCT_IMAGE_INDEX.DETAIL : PRODUCT_IMAGE_INDEX.STANDARD;
    },

    getImageListForPDP(): ProductImage[] {
      if (localCache.imageUrlsPDP !== undefined) {
        return localCache.imageUrlsPDP;
      }
      const imageList = this.getImageList();
      const sortedImageList = [...imageList];
      // rule is to swap index 2 and 4 places if both available
      const imageIndex4: number = sortedImageList.findIndex(
        (image) => getImageIndexFromUrl(image?.url ?? '').index === PRODUCT_IMAGE_INDEX.DETAIL,
      );
      const imageIndex2: number = sortedImageList.findIndex(
        (image) => getImageIndexFromUrl(image?.url ?? '').index === PRODUCT_IMAGE_INDEX.BACK,
      );
      if (imageIndex2 >= 0 && imageIndex4 >= 0) {
        sortedImageList.splice(imageIndex2, 1, imageList[imageIndex4]);
        sortedImageList.splice(imageIndex4, 1, imageList[imageIndex2]);
      }
      localCache.imageUrlsPDP = sortedImageList;
      return sortedImageList;
    },

    getSwatchImage(): string {
      const lastImage = masterVariant?.images?.slice(-1)[0];
      const isSwatch = lastImage?.url.includes('_swatch.');
      return isSwatch ? lastImage?.url ?? '' : '';
    },

    getLongDescription(): string {
      return getValueFromLocalizedString(response?.body?.description);
    },

    getFitting(): string {
      return masterAttributes.fitting ?? '';
    },

    getColorId(): string {
      return masterAttributes.color ?? '';
    },

    getColorName(): string {
      return masterAttributes.colorName ?? '';
    },

    getGiftCardColorFromId(): string {
      // 100 is general gift card with white bg
      return this.isGiftCard() && this.getColorId() === '100' ? 'white' : '';
    },

    getVariants(): VariantModel[] {
      if (localCache.variants) {
        return localCache.variants;
      }
      localCache.variants =
        response?.body?.variants === undefined && response?.body?.masterVariant === undefined
          ? variantListModel(null).getVariantModelList()
          : variantListModel([response?.body?.masterVariant, ...(response?.body?.variants ?? [])])
              .getVariantModelList()
              .filter((variant) => variant.hasPrice());
      return localCache.variants;
    },

    getSizes(isComingSoon = false): string[] {
      const cacheKey: number = isComingSoon ? 1 : 0;
      if (localCache.sizes[cacheKey]) {
        return localCache.sizes[cacheKey];
      }
      const variantList: VariantModel[] = this.getVariants();
      const sizes: string[] = variantList.reduce((sizeList: string[], variant: VariantModel) => {
        const availability: VariantAvailability = variant.getAvailability();
        if (isComingSoon || availability.isInStock || availability.isComingSoon) {
          sizeList.push(variant.getSize());
        }
        return sizeList;
      }, []);
      sortSizes(sizes);
      localCache.sizes[cacheKey] = sizes;
      return sizes;
    },

    getRefinementColorName(): string {
      return masterAttributes.refinementColor?.[0] ?? '';
    },

    getSlug(): string {
      return getValueFromLocalizedString(response?.body?.slug);
    },

    getUrl(size?: string): string {
      let sizeHash = '';
      if (size !== undefined && size.length > 0) {
        sizeHash = `#size=${encodeURIComponent(size)}`;
      }
      let prefix = `/${constants.PDP_SLUG_PREFIX}`;
      if (this.isGiftCard()) {
        prefix = `/${URLS.GIFT_CARD}/`;
      }
      return `${prefix}${this.getSlug()}${decodeURIComponent(sizeHash.replace(/%20/g, ''))}`;
    },

    getAvailabilityFlag(showSoldOutFlag = true) {
      if (localCache.availabilityFlag !== undefined) {
        return localCache.availabilityFlag;
      }
      let availabilityFlag = '';
      if (this.isSoonAvailable() || this.isInTransit()) {
        availabilityFlag = SOON_AVAILABLE_TRANSLATION_KEY;
      } else if (showSoldOutFlag && this.isSoldOut()) {
        availabilityFlag = SOLD_OUT_TRANSLATION_KEY;
      }
      localCache.availabilityFlag = availabilityFlag;
      return availabilityFlag;
    },

    // flag = custom flag
    getFlag(showSoldOutFlag = true): string | undefined {
      if (showSoldOutFlag && localCache.flag !== undefined) {
        return localCache.flag;
      }
      const availabilityFlag: string | undefined = this.getAvailabilityFlag(showSoldOutFlag);
      if (availabilityFlag) {
        localCache.flag = availabilityFlag;
        return localCache.flag;
      }
      localCache.flag = this.getFlagWithoutAvailability();
      return localCache.flag;
    },

    getFlagWithoutAvailability(): string | undefined {
      const { customCampaignLabel, customProductLabel } = masterAttributes ?? {};
      if (customCampaignLabel) {
        return customCampaignLabel;
      }
      if (customProductLabel) {
        return customProductLabel;
      }
      if (this.getSustainabilityId()) {
        return SUSTAINABILITY_FLAG_TRANSLATION_KEY;
      }
    },

    hasSustainabilityFlag() {
      return this.getFlag() === SUSTAINABILITY_FLAG_TRANSLATION_KEY;
    },

    hasSustainabilityFlagWithoutAvailability() {
      return this.getFlagWithoutAvailability() === SUSTAINABILITY_FLAG_TRANSLATION_KEY;
    },

    getCountrySalePermission(): string[] {
      return masterAttributes.countrySalePermission?.map((permission: string) => permission.toLowerCase()) ?? [];
    },

    getBreadcrumbPath(mopId = '.', checkCategoryCb?: Function): string {
      // gtm tracker calls without category id and cache gets wrong when navigating from plp
      let cache: string | undefined = localCache.breadcrumbs[mopId];
      if (cache) {
        return cache;
      }
      const categories: string[] = this.getMopCategoryIds();
      if (!categories || categories.length === 0) {
        return '';
      }
      // try to find breadcrumb for given category
      if (mopId !== '.') {
        cache = categories.find((id) => mopId === id);
      }
      cache ??= categories.reduce((longestPath: string, category: string) => {
        if (
          /unisex|sale|outlet|basics|new|seo|test/gi.test(category) ||
          category.split('-').length <= longestPath.split('-').length
        ) {
          return longestPath || category;
        }
        if (checkCategoryCb) {
          return checkCategoryCb(category) ? category : longestPath;
        }
        return category;
      }, '');
      localCache.breadcrumbs[mopId] = cache;
      return cache;
    },
    getLongestDenimCategory(): string {
      const categories: string[] = this.getMopCategoryIds();
      if (!categories || categories.length === 0) {
        return '';
      }
      return categories.reduce((longestPath: string, category: string) => {
        if (category.includes(MOP_CATEGORY_IDS.DENIM) && category.split('-').length >= longestPath.split('-').length) {
          return category;
        }
        return longestPath;
      }, '');
    },

    getAlternates(paramSlug = ''): Alternate[] {
      let masterVariantAlternates: { [key: string]: string } = {};
      try {
        masterVariantAlternates = JSON.parse(masterAttributes.alternates);
      } catch (error) {
        // Do nothing
      }
      const countrySalePermission = this.getCountrySalePermission();
      const alternates: Alternate[] = [];
      localeList.forEach((mopLocale) => {
        const countrySalePermissionCheck = countrySalePermission.includes(mopLocale.code.split('-')[1]);
        // SE controles all english speaking countries (COM countries)
        const comCountryPermissionCheck = countrySalePermission.includes('se') && mopLocale.lang === 'en';
        if (!countrySalePermissionCheck && !comCountryPermissionCheck) {
          return;
        }
        alternates.push({
          href: `/${constants.PDP_SLUG_PREFIX}${masterVariantAlternates[mopLocale.lang] || ''}${paramSlug}`,
          lang: mopLocale.code,
        });
      });
      return alternates;
    },

    getSellingPoints(range: 'material' | 'sizeFit' | 'deviatingFit'): string[] {
      const ranges: any = {
        material: {
          from: 0,
          to: 7,
        },
        sizeFit: {
          from: 8,
          to: 13,
        },
        deviatingFit: {
          from: 14,
          to: 14,
        },
      };
      const { from, to } = ranges[range];
      const sellingPoints: string[] = [];

      for (let index = from; index <= to; index++) {
        const sellingPoint = masterAttributes[`sellingPoint${index}`];
        if (sellingPoint) {
          sellingPoints.push(sellingPoint);
        }
      }
      return sellingPoints;
    },

    getDeviatingFit(): 'small' | 'large' | null {
      const deviatingFit: number = parseInt(this.getSellingPoints('deviatingFit')[0]);
      if (deviatingFit === -1) {
        return 'small';
      }
      if (deviatingFit === 1) {
        return 'large';
      }
      return null;
    },

    getSustainabilityId(): string {
      return masterAttributes.sustainability?.[0] ?? '';
    },

    getSustainableCertificatePercentage(): string {
      const certificateSplit = (masterAttributes.sustainableCertificate || '').split(' ');
      if (certificateSplit.length === 1) {
        return '';
      }
      return certificateSplit[0];
    },

    getSustainableCertificateName(): string {
      const certificateSplit = (masterAttributes.sustainableCertificate || '').split(' ');
      if (certificateSplit.length === 1) {
        return certificateSplit[0];
      }
      return certificateSplit[1];
    },

    getDebugData() {
      return response;
    },

    getIsOnSaleIn(): string[] {
      return masterAttributes.isOnSaleIn || [];
    },

    getModelSize() {
      const modelSize = masterAttributes.modelSize;
      const patternSize = masterAttributes.patternSize;
      if (!modelSize || !patternSize) {
        return null;
      }
      return {
        modelSize,
        patternSize,
      };
    },

    getModelAlsoWearsSkus(colorId: string): string[] {
      const modelAlsoWearsString: string = masterAttributes.modelAlsoWears ?? '';
      let modelAlsoWears: Record<string, string[]>;
      if (modelAlsoWearsString.length === 0) {
        return [];
      }
      try {
        modelAlsoWears = JSON.parse(modelAlsoWearsString);
      } catch (error) {
        logWarning(`Attribute 'modelAlsoWears' on product ${this.getMopId()} is not a valid JSON.`);
        return [];
      }

      return modelAlsoWears[colorId] ?? [];
    },

    isProduct(): boolean {
      return !this.isGiftCard() && !this.isCorrectionGlasses();
    },

    isGiftCard(): boolean {
      return this.getMopMasterId() === '999999999999';
    },

    isCorrectionGlasses(): boolean {
      const ids = [
        'women-accessories-correction-glasses',
        'men-accessories-correction-glasses',
        'women-accessories-correctionglasses',
        'men-accessories-correctionglasses',
      ];
      return this.getMopCategoryIds().some((id) => ids.includes(id));
    },

    isFragrance(): boolean {
      return this.getMopCategoryIds().some((id) => id.includes('fragrance'));
    },

    getMaterialComposition(): string {
      return masterAttributes.materialComposition ?? '';
    },

    isDenim(): boolean {
      return this.getBrandId() === 'denim';
    },

    storeProductAsTriedWithVirtualTryOn() {
      const id = this.getVirtualTryOnSku();
      const availableProducts = JSON.parse(localStorageGet(constants.LOCAL_STORAGE.VIRTUAL_TRY_ON) || '[]');
      if (availableProducts.includes(id)) {
        return;
      }
      availableProducts.push(id);
      localStorageSet(constants.LOCAL_STORAGE.VIRTUAL_TRY_ON, JSON.stringify(availableProducts));
    },

    getVirtualTryOnSku(): string {
      return `${this.getMopMasterId()}_${this.getColorId()}`;
    },

    isProductTriedWithVirtualTryOn(): boolean {
      const id = this.getVirtualTryOnSku();
      const availableProducts = JSON.parse(localStorageGet(constants.LOCAL_STORAGE.VIRTUAL_TRY_ON) || '[]');
      return availableProducts.includes(id);
    },

    isVirtualTryOnEnabled(): boolean {
      return masterAttributes.virtualTryOn || false;
    },

    isDesignedForCircularity(): boolean {
      return masterAttributes.designedforCircularity || false;
    },
  };
}

export function productListModel(
  responseData: ClientResponse<ProductPagedSearchResponse | ProductProjectionPagedSearchResponse> | null,
) {
  const localCache: {
    swatches?: ProductModel[];
  } = {};

  return {
    isInitialized(): boolean {
      return responseData !== null;
    },

    hasError(): boolean {
      return responseData?.statusCode !== undefined && responseData.statusCode !== 200;
    },

    getFirstProductModel() {
      return this.getProductModelList()?.[0] ?? productModel(null);
    },

    getProductModelList(): ProductModel[] {
      return (
        responseData?.body?.results.map((product) =>
          productModel({
            statusCode: 200,
            // Hacky workaround - needed for search projection api call in search
            body: ('productProjection' in product
              ? product?.productProjection ?? product
              : product) as ProductProjection,
          }),
        ) ?? []
      );
    },

    getSwatches(): ProductModel[] {
      if (localCache.swatches || !responseData?.body?.results) {
        return localCache.swatches || [];
      }

      localCache.swatches = this.getProductModelList().sort((productA: ProductModel, productB: ProductModel) => {
        // Lowercase because of weird safari behaviour
        const colorNameA = `${productA.getColorName().toLowerCase()}-${productA.getMopId()}`;
        const colorNameB = `${productB.getColorName().toLowerCase()}-${productB.getMopId()}`;
        return colorNameA.localeCompare(colorNameB);
      });
      return localCache.swatches || [];
    },

    getDebugData() {
      return responseData;
    },
  };
}

import { securedWrap } from '@mop/shared/utils/securedWrap';
import type { GoogleMapMarkerIcon } from '@mop/ui/types/googleMap';
import { isNumber } from '@mop/shared/utils/util';
import type { StoreFinderFilters, StoreModel, StoreSearchParams } from '@/types/store';
import { storeListModel, storeModel } from '@/models';
import type { LocationFinderItemData } from '@/types/locationFinder';

type LoadingState = {
  searchStores: boolean;
  searchStore: boolean;
  loading: boolean;
};

type StoreFinderPayloadParameters = {
  location: string;
  geolocation: string;
  filters: StoreFinderFilters;
};

export default function useMopStoreFinder() {
  const loadingRef: Ref<LoadingState> = ref({
    searchStores: false,
    searchStore: false,
    loading: computed(() => isLoading(loadingRef)),
  });

  const storeListModelRef = ref(storeListModel(null));
  const storeModelRef = ref<StoreModel>();

  async function searchStores(params: StoreSearchParams) {
    loadingRef.value.searchStores = true;
    const result = await useNuxtApp().$apiBff.getStores(params);
    storeListModelRef.value = storeListModel(result);
    loadingRef.value.searchStores = false;
    return result.data?.code;
  }

  async function searchStore(id: string, params?: { ean?: string }) {
    loadingRef.value.searchStore = true;
    const result = await useNuxtApp().$apiBff.getStore(id, params);
    if (result.data?.store) {
      storeModelRef.value = storeModel(result.data.store);
    }
    loadingRef.value.searchStore = false;
    return result.data?.code;
  }

  function mapStoreToLocation(store: StoreModel): LocationFinderItemData {
    const address = store.getAddress();

    let icon: GoogleMapMarkerIcon = 'pin-filled.svg';
    let selectedIcon: GoogleMapMarkerIcon = 'star-filled.svg';
    const stock: number | null = store.getStockQuantity();

    if (stock === 0) {
      icon = 'pin-filled-red.svg';
      selectedIcon = 'star-filled-red.svg';
    } else if (stock && stock > 0) {
      icon = 'pin-filled-green.svg';
      selectedIcon = 'star-filled-green.svg';
    }

    return {
      id: store.getGln(),
      name: store.getName(),
      address: address
        ? `${[address.street, address.streetNumber].filter(Boolean).join(' ')}, ${address.postalCode} ${address.city}`
        : '',
      openingTimes: store.getOpeningTimes(),
      country: store.getCountry(),
      geoLocation: store.getStoreCoordinates(),
      isReserveAndCollectEnabled: store.isReserveAndCollectEnabled(),
      stock,
      mapMarkerIcon: icon,
      mapMarkerSelectedIcon: selectedIcon,
      phoneNumber: store.getPhoneNumber(),
    };
  }

  function createPayload(
    country: string,
    { location, geolocation, filters }: StoreFinderPayloadParameters,
  ): StoreSearchParams {
    const isPostalCodeSearch = isNumber(location);
    return {
      postalCode: isPostalCodeSearch ? location : null,
      city: !isPostalCodeSearch ? location : null,
      location: geolocation || null,
      findAvailableStore: filters.findAvailableStore || null,
      ean: filters.ean,
      countryCode: country,
    } as StoreSearchParams;
  }

  async function searchStoreByGln(gln: string) {
    loadingRef.value.searchStores = true;
    const result = await useNuxtApp().$apiBff.getStoreByGln(gln);
    if (result.data?.store) {
      storeModelRef.value = storeModel(result.data.store);
    }
    loadingRef.value.searchStores = false;
    return result.data?.code;
  }

  return securedWrap({
    storeListModelRef,
    storeModelRef,
    searchStores,
    searchStore,
    mapStoreToLocation,
    createPayload,
    searchStoreByGln,
    loadingRef,
  });
}

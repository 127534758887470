<script setup lang="ts">
import { throttleWithoutDelay } from '@mop/shared/utils/util';
import { ref, onMounted, onUnmounted, computed, watch } from 'vue';

defineOptions({
  name: 'Ui2CarouselScrollBar',
});

const props = defineProps({
  scrollWidth: {
    type: Number,
    default: 0,
  },
  scrollLeft: {
    type: Number,
    default: 0,
  },
});

const emit = defineEmits(['update:positionLeft']);

const scrollbarElementRef = ref<HTMLElement>();
const thumbElementRef = ref<HTMLElement>();
const dragging = ref(false);

let dragStartX = 0;
let thumbStartLeft = 0;

const thumbWidth = ref(0);
const thumbLeft = ref(0);

const clientWidth = ref(0);
const scrollLeft = computed(() => props.scrollLeft);
const scrollWidth = computed(() => props.scrollWidth);

const handleMouseMove = throttleWithoutDelay((event: MouseEvent) => {
  if (!dragging.value) return;
  const delta = event.clientX - dragStartX;
  let newLeft = thumbStartLeft + delta;
  newLeft = Math.max(0, Math.min(newLeft, clientWidth.value - thumbWidth.value));
  thumbLeft.value = newLeft;
});

function updateMeasurements() {
  if (!scrollbarElementRef.value || !thumbElementRef.value) {
    return;
  }
  clientWidth.value = scrollbarElementRef.value.clientWidth;

  const visibleRatio = clientWidth.value / scrollWidth.value;
  thumbWidth.value = clientWidth.value * visibleRatio;
  updateThumbPosition();
}

function updateThumbPosition() {
  const maxScrollLeft = scrollWidth.value - clientWidth.value;
  const scrollFraction = scrollLeft.value / maxScrollLeft;
  const maxThumbLeft = clientWidth.value - thumbWidth.value;

  thumbLeft.value = scrollFraction * maxThumbLeft;
}

const handleMouseDown = (event: MouseEvent) => {
  if (thumbElementRef.value === event.target) {
    dragging.value = true;
    dragStartX = event.clientX;
    thumbStartLeft = thumbLeft.value;
  } else {
    // clicked on scroll bg
    if (!scrollbarElementRef.value) {
      return;
    }
    const clickX = event.clientX - scrollbarElementRef.value.getBoundingClientRect().left;
    let newLeft = clickX - thumbWidth.value / 2;
    newLeft = Math.max(0, Math.min(newLeft, clientWidth.value - thumbWidth.value));
    thumbLeft.value = newLeft;
    emitPosition();
  }
};

function handleMouseUp() {
  dragging.value = false;
}

function emitPosition() {
  const maxThumbLeft = clientWidth.value - thumbWidth.value;
  const scrollFraction = maxThumbLeft > 0 ? thumbLeft.value / maxThumbLeft : 0;
  const maxScrollLeft = scrollWidth.value - clientWidth.value;
  const newScrollLeft = scrollFraction * maxScrollLeft;

  emit('update:positionLeft', newScrollLeft);
}

const handleKeyDown = (event: KeyboardEvent) => {
  if (event.key !== 'ArrowLeft' && event.key !== 'ArrowRight') {
    return;
  }
  let newLeft = thumbLeft.value;
  if (event.key === 'ArrowRight') {
    newLeft += 15;
  } else if (event.key === 'ArrowLeft') {
    newLeft -= 15;
  }
  newLeft = Math.max(0, Math.min(newLeft, clientWidth.value - thumbWidth.value));
  thumbLeft.value = newLeft;
  emitPosition();
};

onMounted(() => {
  updateThumbPosition();

  scrollbarElementRef.value?.addEventListener('mousedown', handleMouseDown);
  document.addEventListener('mousemove', handleMouseMove);
  document.addEventListener('mouseup', handleMouseUp);

  watch(() => props.scrollLeft, updateThumbPosition);
  watch(() => props.scrollWidth, updateMeasurements, { immediate: true });
  watch(thumbLeft, () => {
    if (dragging.value) {
      emitPosition();
    }
  });
});

onUnmounted(() => {
  scrollbarElementRef.value?.removeEventListener('mousedown', handleMouseDown);
  document.removeEventListener('mousemove', handleMouseMove);
  document.removeEventListener('mouseup', handleMouseUp);
});
</script>

<template>
  <div class="ui-carousel-scroll-bar-wrapper">
    <div
      ref="scrollbarElementRef"
      :class="['ui-carousel-scroll-bar', { 'ui-carousel-scroll-bar--dragging': dragging }]"
    >
      <button
        ref="thumbElementRef"
        class="ui-carousel-scroll-bar__button"
        :style="`width: ${thumbWidth}px; transform: translateX(${thumbLeft}px);`"
        :aria-label="$ui2Config.ui2T('common.global.scroll')"
        @keydown="handleKeyDown"
      />
    </div>
  </div>
</template>

<style lang="scss">
.ui-carousel-scroll-bar-wrapper {
  display: flex;
  align-items: center;
  height: 8px;
}

.ui-carousel-scroll-bar {
  position: relative;
  display: flex;
  width: 100%;
  height: 2px;
  align-items: center;
  cursor: pointer;
  border-radius: 50px;
  background: $color-surface-disabled;

  &:hover {
    height: $space-4;
  }
}

.ui-carousel-scroll-bar__button {
  position: relative;
  height: 2px;
  border: none;
  padding: 0;
  margin: 0;
  cursor: grab;
  border-radius: 30px;
  background: $color-surface-action-1;

  &:hover {
    height: 4px;
  }

  &::after {
    @include v2-z(global, content);
    position: absolute;
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
    border: 2px solid $color-border-focus;
    border-radius: 30px;
    transition: all $animation-duration-medium $animation-type-standard;
    opacity: 0;
    content: '';
  }

  &:focus-visible {
    height: $space-8;

    &::after {
      opacity: 1;
    }
  }
}

.ui-carousel-scroll-bar--dragging {
  height: 4px;

  .ui-carousel-scroll-bar__button {
    height: 8px;
  }
}
</style>

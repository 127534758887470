import { securedWrap } from '@mop/shared/utils/securedWrap';

export default function useMopCartOverlayClient() {
  type MopCartOverlayComposableStorage = {
    showAddToCartOverlayRef: Ref<boolean>;
  };

  const storage = initStorage<MopCartOverlayComposableStorage>('useMopCartOverlayClient');
  const showAddToCartOverlayRef =
    storage.get('showAddToCartOverlayRef') ?? storage.saveAndGet('showAddToCartOverlayRef', ref(false));

  function openCartOverlay() {
    showAddToCartOverlayRef.value = true;
  }

  function closeCartOverlay() {
    showAddToCartOverlayRef.value = false;
  }

  return securedWrap({
    openCartOverlay,
    closeCartOverlay,
    showAddToCartOverlayRef,
  });
}

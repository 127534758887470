<script setup lang="ts">
import type { PropType, Component } from 'vue';
import type { IconName } from '@mop/ui2/types';
import useUi2Config from '@mop/ui2/composables/useUi2Config';
const { svgIcons } = useUi2Config();

defineOptions({
  name: 'Ui2Icon',
});

const props = defineProps({
  name: {
    type: String as PropType<IconName>,
    required: true,
  },
  width: {
    type: Number,
    required: true,
  },
  height: {
    type: Number,
    required: true,
  },
  color: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
});
const SvgIconComponent: undefined | (() => Promise<Component>) = svgIcons[`../../icons/${props.name}.svg`];
const LazyIcon = SvgIconComponent ? await SvgIconComponent() : null;
</script>

<template>
  <LazyIcon
    v-if="LazyIcon"
    :key="name"
    :class="`ui-svg-icon ${color}`"
    :width="width"
    :aria-hidden="!label"
    :aria-label="label ? `Icon: ${label}` : undefined"
    :height="height"
    :style="{ width, height, 'min-width': width, 'min-height': height }"
  />
</template>

<style lang="scss">
.ui-svg-icon {
  fill: currentColor;
  display: flex;
}
</style>

// Based on https://github.com/Akryum/vue-observe-visibility
import type { Directive, DirectiveBinding } from 'vue';
import type { ObserveVisibilityConfig } from '@mop/types';

type HtmlElementVisibility = HTMLElement & {
  // eslint-disable-next-line no-use-before-define
  _vue_visibilityState: VisibilityState | null;
};

class VisibilityState {
  el: HtmlElementVisibility | null;
  observer: IntersectionObserver | null;
  frozen: boolean | null;
  callback: Function | undefined;
  delay: number | undefined;

  constructor(el: HtmlElementVisibility, options: ObserveVisibilityConfig) {
    this.el = el;
    this.frozen = false;
    this.observer = null;
    this.delay = options.delay ?? 50;
    this.createObserver(options);
  }

  createObserver(options: ObserveVisibilityConfig) {
    if (this.observer) {
      this.destroyObserver();
    }

    if (this.frozen) {
      return;
    }

    this.callback = (isVisible: boolean, entry: IntersectionObserverEntry) => {
      options.callback(isVisible, entry);
      if (isVisible && options.once) {
        this.frozen = true;
        this.destroyObserver();
      }
    };

    // Wait for the element to be in document
    nextTick(() => {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const state: VisibilityState = this;
      setTimeout(() => {
        state.observer = new IntersectionObserver(
          (entries) => {
            const entry: IntersectionObserverEntry = entries[0];
            state.callback && state.callback(entry.isIntersecting, entry);
          },
          {
            rootMargin: options.rootMargin,
            threshold: options.threshold,
          },
        );

        if (state.observer && state.el) {
          state.observer.observe(state.el);
        }
      }, state.delay);
    });
  }

  destroyObserver() {
    if (this.observer) {
      this.observer.disconnect();
      this.observer = null;
    }
    this.callback = undefined;
    this.frozen = null;
    this.delay = undefined;
    if (this.el) {
      this.el._vue_visibilityState = null;
    }
    this.el = null;
  }
}

export const visibilityDirective: Directive = {
  mounted(el: HtmlElementVisibility, { arg, value }: DirectiveBinding) {
    if (!value || typeof window.IntersectionObserver === 'undefined') {
      // Should not happen, because of polyfill
      return;
    }
    // Simple options (callback-only) or options object
    const options: ObserveVisibilityConfig = typeof value !== 'function' ? value : { callback: value };
    options.once = options.once ?? arg === 'once';
    options.rootMargin = options.rootMargin || '0px 0px 100% 0px';
    options.threshold = options.threshold || 0;
    el._vue_visibilityState = new VisibilityState(el, options);
  },

  unmounted(el: HtmlElementVisibility) {
    const state = el._vue_visibilityState;
    if (state) {
      state.destroyObserver();
      el._vue_visibilityState = null;
    }
  },
};

<script setup lang="ts">
import type { PropType } from 'vue';
import Ui2Loader from '../Ui2Loader/Ui2Loader.vue';
import type { IconName, UiButtonAppearances, UiButtonHtmlTypes, UiButtonSizes, UiButtonTypes } from '@mop/ui2/types';

defineOptions({
  name: 'Ui2Button',
  inheritAttrs: false,
});

const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  size: {
    type: String as PropType<UiButtonSizes>,
    default: 'md',
  },
  fullWidth: {
    type: Boolean,
    default: false,
  },
  appearance: {
    type: String as PropType<UiButtonAppearances>,
    default: 'default',
  },
  type: {
    type: String as PropType<UiButtonTypes>,
    default: 'filled',
  },
  htmlType: {
    type: String as PropType<UiButtonHtmlTypes>,
    default: 'button',
  },
  iconStart: {
    type: String as PropType<IconName>,
    default: '',
  },
  iconEnd: {
    type: String as PropType<IconName>,
    default: '',
  },
  loading: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  to: {
    type: String,
    default: undefined,
  },
});

let htmlComponent = 'button';
if (props.htmlType === 'link' || props.to) {
  htmlComponent = 'Ui2NuxtLink';
} else if (props.htmlType === 'div') {
  htmlComponent = 'div';
}
const buttonType = htmlComponent === 'button' ? props.htmlType : '';
const iconSize = props.size === 'lg' ? 20 : 16;
const closeIconSize = props.size === 'lg' ? 24 : props.size === 'sm' ? 16 : 20;
const isIconOnly = !props.label && props.iconStart && !props.iconEnd;
</script>

<template>
  <component
    :is="htmlComponent"
    :class="[
      'ui-button',
      size ? `ui-button--${size}` : '',
      type ? `ui-button--${type}` : '',
      appearance ? `ui-button--${appearance}` : '',
      {
        'ui-button--disabled': disabled,
        'ui-button--square': isIconOnly,
        'ui-button--loading': loading,
        'ui-button--full-width': fullWidth,
      },
    ]"
    v-bind="$attrs"
    :disabled="disabled"
    :type="buttonType"
    :title="label"
    :component-name="htmlType === 'link' || to ? 'NuxtLink' : null"
    :to="to"
  >
    <template v-if="type === 'close'">
      <Ui2Icon class="ui-button__close" :name="iconStart || 'x'" :width="closeIconSize" :height="closeIconSize" />
    </template>
    <template v-else-if="loading">
      <Ui2Loader v-if="loading" :size="size" />
    </template>
    <template v-else>
      <Ui2Icon
        v-if="iconStart"
        class="ui-button__icon ui-button__icon--left"
        :name="iconStart"
        :width="iconSize"
        :height="iconSize"
      />
      <span v-if="$slots.default" class="ui-button__label"><slot name="default" /></span>
      <span v-else-if="label" class="ui-button__label">{{ label }}</span>

      <Ui2Icon
        v-if="iconEnd"
        class="ui-button__icon ui-button__icon--right"
        :name="iconEnd"
        :width="iconSize"
        :height="iconSize"
      />
    </template>
  </component>
</template>

<style lang="scss">
$filledStyles: (
  default: (
    default: (
      color: $color-text-on-action,
      background: $color-surface-action-1,
    ),
    hover: (
      background: $color-surface-action-1-hover,
    ),
    active: (
      background: $color-surface-action-1-active,
    ),
    after: (
      border-color: $color-border-focus,
    ),
  ),
  inverted: (
    default: (
      color: $color-text-on-action-inverted,
      background: $color-surface-action-1-inverted,
    ),
    hover: (
      background: $color-surface-action-1-hover-inverted,
    ),
    active: (
      background: $color-surface-action-1-active-inverted,
    ),
    after: (
      border-color: $color-border-focus-inverted,
    ),
  ),
);

$outlineStyles: (
  default: (
    default: (
      color: $color-text-action,
      border-color: $color-text-action,
      background: $color-surface-action-2,
    ),
    hover: (
      background: $color-surface-action-2-hover,
    ),
    active: (
      background: $color-surface-action-2-active,
    ),
    after: (
      border-color: $color-border-focus,
    ),
  ),
  inverted: (
    default: (
      color: $color-text-action-inverted,
      border-color: $color-text-action-inverted,
      background: $color-surface-action-2-inverted,
    ),
    hover: (
      background: $color-surface-action-2-hover-inverted,
    ),
    active: (
      background: $color-surface-action-2-active-inverted,
    ),
    after: (
      border-color: $color-border-focus-inverted,
    ),
  ),
);

$ghostStyles: (
  default: (
    default: (
      color: $color-text-action,
      background: $color-surface-action-2,
    ),
    hover: (
      background: $color-surface-action-2-hover,
    ),
    active: (
      background: $color-surface-action-2-active,
    ),
    after: (
      border-color: $color-border-focus,
    ),
  ),
  inverted: (
    default: (
      color: $color-text-action-inverted,
      background: $color-surface-action-2-inverted,
    ),
    hover: (
      background: $color-surface-action-2-hover-inverted,
    ),
    active: (
      background: $color-surface-action-2-active-inverted,
    ),
    after: (
      border-color: $color-border-focus-inverted,
    ),
  ),
);

$closeStyles: (
  default: (
    default: (
      color: $color-text-action,
      background: $color-surface-action-2,
    ),
    hover: (
      background: $color-surface-action-2-hover,
    ),
    active: (
      background: $color-surface-action-2-active,
    ),
    after: (
      border-color: $color-border-focus,
      border-radius: $border-radius-rounded-full,
    ),
  ),
  inverted: (
    default: (
      color: $color-text-action-inverted,
      background: $color-surface-action-2-inverted,
    ),
    hover: (
      background: $color-surface-action-2-hover-inverted,
    ),
    active: (
      background: $color-surface-action-2-active-inverted,
    ),
    after: (
      border-color: $color-border-focus-inverted,
      border-radius: $border-radius-rounded-full,
    ),
  ),
);

@mixin button-state-styles($styleGroup) {
  @each $color, $styles in $styleGroup {
    &.ui-button--#{$color} {
      @each $css-rule, $value in map.get($styles, 'default') {
        #{$css-rule}: #{$value};
      }

      &:hover:not(:disabled) {
        @each $css-rule, $value in map.get($styles, 'hover') {
          #{$css-rule}: #{$value};
        }
      }

      &:active:not(:disabled) {
        @each $css-rule, $value in map.get($styles, 'active') {
          #{$css-rule}: #{$value};
        }
      }

      @if (map.get($styles, 'after')) {
        &::after {
          @each $css-rule, $value in map.get($styles, 'after') {
            #{$css-rule}: #{$value};
          }
        }
      }
    }
  }
}

.ui-button {
  @include v2-text-style(sm, highlight);
  // color: $color-text-action;
  position: relative;
  text-transform: uppercase;
  margin: 0px;
  padding: 0 $space-16;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: $space-8;
  border-radius: $border-radius-rounded-md;
  transition: all $animation-duration-medium $animation-type-standard;
  border: 0;
  outline: none;
  background: none;
  cursor: pointer;

  &::after {
    @include v2-z(global, content);
    position: absolute;
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
    border: 2px solid currentColor;
    border-radius: $border-radius-rounded-md;
    transition: all $animation-duration-medium $animation-type-standard;
    opacity: 0;
    content: '';
  }

  &:disabled {
    cursor: not-allowed;
    opacity: $v2-disabled-opacity;
  }

  &:active:not(:disabled) {
    scale: 0.97;
  }

  &:focus-visible {
    &::after {
      opacity: 1;
    }
  }
}

.ui-button--square {
  padding: 0;
}

.ui-button--sm {
  height: 32px;

  &.ui-button--square {
    width: 32px;
  }

  &.ui-button--close {
    width: 24px;
    height: 24px;
  }
}

.ui-button--md {
  height: 40px;

  &.ui-button--square {
    width: 40px;
  }

  &.ui-button--close {
    width: 32px;
    height: 32px;
  }
}

.ui-button--lg {
  @include v2-text-style(sm, highlight);
  height: 48px;

  &.ui-button--square {
    width: 48px;
  }

  &.ui-button--close {
    width: 40px;
    height: 40px;
  }
}

.ui-button--filled {
  @include button-state-styles($filledStyles);
}

.ui-button--outline {
  border: 1px solid $color-border-action;

  @include button-state-styles($outlineStyles);
}

.ui-button--ghost {
  @include button-state-styles($ghostStyles);
}

.ui-button--close {
  @include button-state-styles($closeStyles);

  border-radius: $border-radius-rounded-full;
  padding: 0;

  &:active:not(:disabled) {
    scale: 0.9;
  }
}

.ui-button--loading {
  .ui-button__label,
  .ui-button__icon {
    opacity: 0;
  }
}

.ui-button--full-width {
  width: 100%;
}

.ui-button__label {
  // To ensure font is centered vertically
  line-height: 1;
}
</style>

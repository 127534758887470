<script setup lang="ts">
import { onUnmounted, computed, watch } from 'vue';
import type { UiNotificationStatuses } from '@mop/ui2/types';

defineOptions({
  name: 'Ui2Toast',
});
const emit = defineEmits(['update:modelValue']);

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  status: {
    type: String as PropType<UiNotificationStatuses>,
    default: 'default',
  },
  title: {
    type: String,
    default: '',
  },
  width: {
    type: String,
    default: '400px',
  },
  showIcon: {
    type: Boolean,
    default: false,
  },
  showCloseButton: {
    type: Boolean,
    default: false,
  },
  autoCloseDelay: {
    type: Number,
    default: 0,
  },
});

const isVisibleRef = computed(() => props.modelValue);
const transitionAnimation = 'ui-toast-slide-left';
const iconSize = 20;
let timer: any;

function handleCloseOverlay() {
  emit('update:modelValue', !isVisibleRef.value);
}

function resetTimer() {
  clearTimeout(timer);
}

watch(
  isVisibleRef,
  (isVisible) => {
    resetTimer();
    if (props.autoCloseDelay > 0 && isVisible) {
      timer = setTimeout(() => {
        emit('update:modelValue', false);
      }, props.autoCloseDelay * 1000);
    }
  },
  { immediate: true },
);

onUnmounted(resetTimer);
</script>

<template>
  <Transition :name="transitionAnimation">
    <div
      v-if="isVisibleRef"
      v-bind="$attrs"
      :class="[
        'ui-toast',
        `ui-toast--${transitionAnimation}`,
        `ui-toast--${status}`,
        autoCloseDelay > 0 ? 'ui-toast--autoclose' : '',
      ]"
      :aria-hidden="!isVisibleRef"
      role="dialog"
      aria-modal="true"
      tabindex="-1"
      :style="{
        '--width': width,
        '--ui2-toast-duration': autoCloseDelay + 's',
      }"
    >
      <div class="ui-toast__inner">
        <div v-if="showIcon" class="ui-toast__icon-wrap">
          <Ui2Icon
            class="ui-toast__icon ui-toast__icon--info"
            name="info-circle"
            :width="iconSize"
            :height="iconSize"
          />
          <Ui2Icon
            class="ui-toast__icon ui-toast__icon--error"
            name="alert-circle"
            :width="iconSize"
            :height="iconSize"
          />
          <Ui2Icon
            class="ui-toast__icon ui-toast__icon--warning"
            name="alert-triangle"
            :width="iconSize"
            :height="iconSize"
          />
          <Ui2Icon
            class="ui-toast__icon ui-toast__icon--success"
            name="check-circle"
            :width="iconSize"
            :height="iconSize"
          />
          <Ui2Icon
            class="ui-toast__icon ui-toast__icon--default"
            name="info-circle"
            :width="iconSize"
            :height="iconSize"
          />
        </div>
        <Ui2Button v-if="showCloseButton" class="ui-toast__close" type="close" size="md" @click="handleCloseOverlay" />
        <div class="ui-toast__content">
          <div v-if="title" class="ui-toast__title">{{ title }}</div>
          <slot />
          <div v-if="$slots.action" class="ui-toast__action">
            <slot name="action" />
          </div>
        </div>
      </div>
      <div class="ui-toast__progress">
        <div class="ui-toast__progress-bar"></div>
      </div>
    </div>
  </Transition>
</template>

<style lang="scss">
$icon-size: 20px;

.ui-toast {
  --ui2-toast-background-color: var(--color-surface-primary);
  --ui2-toast-border-color: var(--color-border-action);
  --ui2-toast-text-color: var(--color-text-body-primary);
}

.ui-toast--info {
  --ui2-toast-background-color: var(--color-surface-information);
  --ui2-toast-border-color: var(--color-border-information);
  --ui2-toast-text-color: var(--color-text-information);
}

.ui-toast--error {
  --ui2-toast-background-color: var(--color-surface-error);
  --ui2-toast-border-color: var(--color-border-error);
  --ui2-toast-text-color: var(--color-text-error);
}

.ui-toast--warning {
  --ui2-toast-background-color: var(--color-surface-warning);
  --ui2-toast-border-color: var(--color-border-warning);
  --ui2-toast-text-color: var(--color-text-warning);
}

.ui-toast--success {
  --ui2-toast-background-color: var(--color-surface-success);
  --ui2-toast-border-color: var(--color-border-success);
  --ui2-toast-text-color: var(--color-text-success);
}

.ui-toast__icon {
  display: none;
  color: var(--ui2-toast-text-color);
}

.ui-toast--info {
  .ui-toast__icon--info {
    display: block;
  }
}

.ui-toast--error {
  .ui-toast__icon--error {
    display: block;
  }
}

.ui-toast--success {
  .ui-toast__icon--success {
    display: block;
  }
}
.ui-toast--warning {
  .ui-toast__icon--warning {
    display: block;
  }
}
.ui-toast--default,
.ui-toast__icon--info {
  .ui-toast__icon--default {
    display: block;
  }
}

.ui-toast {
  width: var(--width);
  max-width: 100%;
  outline: 0;
  border-radius: $border-radius-rounded-lg;
  border: 1px solid $color-border-secondary;
  box-shadow: $shadow-md;
  overflow: hidden;

  @include v2-apply-upto(mobile) {
    width: 100%;
  }
}

.ui-toast__inner {
  height: 100%;
  width: 100%;
  background-color: var(--ui2-toast-background-color);
  position: relative;
  display: flex;
  gap: $space-16;
  padding: $space-16 $space-48 $space-16 $space-16;
}

.ui-toast__title {
  @include v2-text-style('md', 'highlight');

  color: var(--ui2-toast-text-color);
  margin-bottom: $space-4;
  line-height: $icon-size;
}

.ui-toast__close {
  position: absolute;
  right: $space-8;
  top: $space-8;
}

.ui-toast__content {
  @include v2-text-style('md');
  overscroll-behavior: contain;
  color: $color-text-body-secondary;
  flex-grow: 1;
}

.ui-toast__action {
  color: $color-text-body-primary;
  margin-top: $space-4;
}

.ui-toast__progress {
  background-color: var(--ui2-toast-background-color);
  height: 6px;
}

.ui-toast__progress-bar {
  width: 100%;
  height: 100%;
  background-color: var(--ui2-toast-border-color);
}

.ui-toast--autoclose .ui-toast__progress-bar {
  animation: progressWidth var(--ui2-toast-duration) linear forwards;
}

.ui-toast--visible .ui-toast__progress-bar {
  width: 0;
}

.ui-toast-slide-left-enter-active {
  transition: transform $animation-duration-x-long $animation-type-standard;
}

.ui-toast-slide-left-leave-active {
  transition: transform $animation-duration-long $animation-type-emphasized-accelerate;
}

.ui-toast-slide-left-enter-from,
.ui-toast-slide-left-leave-to {
  transform: translateX(100%);

  @include v2-apply-upto(mobile) {
    transform: translateY(100%);
  }
}

@keyframes progressWidth {
  from {
    width: 100%;
  }
  to {
    width: 0;
  }
}
</style>

<script setup lang="ts">
import type { PropType } from 'vue';
import type { ObserveVisibilityConfig } from '@mop/types';
import type { ProductModel } from '@/types/product';
import type { RecommendationWidget, RecommendationSection } from '@/types/recommendation';

type RecommendationSectionConfig = {
  titleKey?: string;
  GTM: string;
  disabled?: boolean;
};

const recommendationConfig: Record<RecommendationSection, RecommendationSectionConfig> = {
  PRODUCT_PAGE_SIMILAR: {
    titleKey: 'similar_items',
    GTM: 'Recommendations_{TYPE}_detail_similar',
  },
  PRODUCT_PAGE_BOUGHT_TOGETHER: {
    titleKey: 'others_also_liked',
    GTM: 'Recommendations_{TYPE}_detail_bought_together',
  },
  PRODUCT_PAGE_POPULAR: {
    titleKey: 'our_most_popular_items',
    GTM: 'Recommendations_{TYPE}_detail_popular',
  },
  PRODUCT_LIST_PAGE: {
    titleKey: 'complement_your_look',
    GTM: 'Recommendations_{TYPE}_category',
  },
  SEARCH: {
    titleKey: 'recently_viewed',
    GTM: 'Recommendations_{TYPE}_search_page',
  },
  CART_PAGE: {
    titleKey: 'inspired_by_your_recent_purchase',
    GTM: 'Recommendations_{TYPE}_cart_filled',
  },
  CART_PAGE_EMPTY: {
    titleKey: 'best_sellers',
    GTM: 'Recommendations_{TYPE}_cart_empty',
  },
  HOME_PAGE: {
    titleKey: 'your_personal_recommendations',
    disabled: true,
    GTM: 'Recommendations_{TYPE}_home',
  },
  WISHLIST_PAGE: {
    titleKey: 'you_might_also_like',
    GTM: 'Recommendations_{TYPE}_wishlist',
  },
  RESERVE_AND_COLLECT_PAGE: {
    titleKey: 'others_also_liked',
    GTM: 'Recommendations_{TYPE}_reservecollect',
  },
  ORDER_CONFIRM_PAGE: {
    titleKey: 'others_also_liked',
    GTM: 'Recommendations_{TYPE}_purchase',
  },
  CART_OVERLAY: {
    titleKey: 'recently_viewed',
    GTM: 'Recommendations_{TYPE}_detail_addtocart',
  },
  SEARCH_OVERLAY_NO_RESULT: {
    titleKey: 'recently_viewed',
    GTM: 'Recommendations_{TYPE}_search_overlay',
  },
  CATEGORY_LANDING_PAGE_B: {
    titleKey: 'your_personal_recommendations',
    GTM: 'Recommendations_{TYPE}_home',
  },
  ERROR_PAGE: {
    titleKey: 'recently_viewed',
    GTM: 'Recommendations_{TYPE}_error_page',
  },
};

defineOptions({
  name: 'MopRecommendation',
});

const props = defineProps({
  widget: {
    type: String as PropType<RecommendationWidget>,
    required: true,
  },
  categoryPath: {
    type: String,
    default: '',
  },
  section: {
    type: String as PropType<RecommendationSection>,
    required: true,
  },
  selector: {
    type: [String, Number, Array],
    default: null,
  },
  maxSlidesCount: {
    type: Number,
    default: 4,
  },
  showSliderPeek: {
    type: Boolean,
    default: false,
  },
  disableLoading: {
    type: Boolean,
    default: false,
  },
  hideBrandName: {
    type: Boolean,
    default: false,
  },
  hideFlag: {
    type: Boolean,
    default: false,
  },
  disableHover: {
    type: Boolean,
    default: false,
  },
  useCustomProductList: {
    type: Boolean,
    default: false,
  },
  productList: {
    type: Array as PropType<ProductModel[]>,
    default: () => [],
  },
  epoqWidget: {
    type: String,
    required: false,
    default: undefined,
  },
  recommendationId: {
    type: String,
    default: '',
  },
  showArrows: {
    type: Boolean,
    default: true,
  },
  showScrollbar: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['loaded']);

const { $mopI18n } = useNuxtApp();
const config = useRuntimeConfig();

const wrapperElement = ref<HTMLElement | null>(null);
const { searchRecommendation, productModelsRef, reportEngagement, recommendationModelRef, getEpoqWidgetName } =
  useMopRecommendationClient();
const { isEpoqConsentAccepted } = useMopUsercentricsConsentClient();
const { loadingRef: pageLoadingRef } = useMopPageTransitionClient();
const loadingRef = ref(true);
const itemsLoadedRef = ref(false);
const isVisibleRef = ref(false);
const gtmTypeRef: any = ref('');
const epoqWidgetName = props.epoqWidget ?? getEpoqWidgetName(props.widget);

const productListRef = computed(() => (props.useCustomProductList ? props.productList : productModelsRef.value));

const titleRef = computed(() => {
  const titleKey = recommendationConfig[props.section]?.titleKey;
  if (!titleKey) {
    return '';
  }
  const translationKey = `components.recommendation.${titleKey}`;
  return $mopI18n.te(translationKey) ? $mopI18n.t(translationKey) : '';
});

async function init() {
  const isEpoqConsent = await isEpoqConsentAccepted();
  const recommendationType = isEpoqConsent ? 'epoqPersonalized' : 'epoqAnonymized';
  const section = recommendationConfig[props.section];
  gtmTypeRef.value = section.GTM.replace('{TYPE}', recommendationType);

  if (!section || section.disabled || !config.public.RECOMMENDATION_ENABLED) {
    return;
  }

  const stopProductWatcher = watch(productListRef, (productList) => {
    if (productList.length === 0) {
      return;
    }
    const wrapper = wrapperElement.value;
    if (wrapper?.classList[0]) {
      wrapper.classList.add(`${wrapper.classList[0]}--visible`);
    }
    emit('loaded');
    stopProductWatcher();
  });

  if (!props.useCustomProductList) {
    await searchRecommendation(props.widget);
  }

  itemsLoadedRef.value = true;
}

function handleSliderMounted() {
  nextTick(() => {
    loadingRef.value = false;
  });
}

function handleEngagement(product: ProductModel) {
  reportEngagement(
    product.getMopId(),
    props.useCustomProductList
      ? props.recommendationId
      : recommendationModelRef.value.getRecommendationIdsByDomain()[0],
  );
}

const visibilityConfig: ObserveVisibilityConfig = {
  callback: (isVisible) => {
    isVisibleRef.value = isVisible;
  },
  delay: 500,
};

onMounted(() => {
  const { hasUserInteractedRef } = useMopGlobalEventsClient();
  const stopUserInteractedWatch = watch([hasUserInteractedRef, isVisibleRef], async () => {
    if (hasUserInteractedRef.value && isVisibleRef.value) {
      await init();
      stopUserInteractedWatch();
    }
  });
});
</script>

<template>
  <div
    v-if="!pageLoadingRef && (!itemsLoadedRef || productListRef.length > 0 || isProductionBuild === false)"
    ref="wrapperElement"
    v-observe-visibility:once="visibilityConfig"
    :style="'position: relative;'"
  >
    <div v-if="!disableLoading && loadingRef" class="recommendation__loading">
      <div class="recommendation__loading-sizer" />
    </div>
    <div
      v-if="productListRef.length > 0 && gtmTypeRef"
      :id="`epoq-widget-${epoqWidgetName}`"
      :class="[
        'recommendation',
        {
          'recommendation--loaded': !loadingRef,
        },
      ]"
    >
      <h3 v-show="titleRef !== ''" class="recommendation__title">
        {{ titleRef }}
      </h3>
      <Ui2Carousel
        :show-arrows="showArrows"
        is-product-tiles
        :show-peek="showSliderPeek"
        :max-slides-count="maxSlidesCount"
        :show-scrollbar="showScrollbar"
        @mounted="handleSliderMounted"
      >
        <MopProductTile
          v-for="product in productListRef"
          :key="`reco-${product.getMopId()}`"
          :product="product"
          image-type="product-tile-reco"
          :gtm-list-type="gtmTypeRef"
          :image-index="product.getImageIndexForRecos()"
          :image-hover-index="product.getImageIndexForRecos(true)"
          :category-path="categoryPath"
          :hide-brand-name="hideBrandName"
          :hide-flag="hideFlag"
          :disable-hover="disableHover"
          @click="handleEngagement(product)"
        />
      </Ui2Carousel>
    </div>
  </div>
</template>

<style scoped lang="scss">
.recommendation,
.recommendation-wrapper {
  position: relative;
}

.recommendation {
  visibility: hidden;
  position: absolute;
  width: 100%;
}

.recommendation--loaded {
  visibility: visible;
  position: relative;
}

.recommendation__loading {
  display: grid;
  grid-template-columns: repeat(4, 2fr) 1fr;
  column-gap: $space-8;
  background: $light-grey;

  @include v2-apply-upto(mobile) {
    grid-template-columns: repeat(2, 2fr) 1fr;
  }
}

.recommendation__loading-sizer {
  padding-top: calc((1.4035 / 1 * 100%) + 140px);

  @include v2-apply-upto(mobile) {
    padding-top: calc((1.4035 / 1 * 100%) + 120px);
  }
}

.recommendation__title {
  @include v2-text-style('3xl', highlight);
  padding: 0;
  margin: 0 0 $space-24 0;
}
</style>
